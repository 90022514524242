import styled from "styled-components"

export default styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: ${({ theme, inset }) =>
    inset && inset.top ? theme.overrides.NavigationBar.height : 0}px;
`

export const Main = styled.main`
  flex-grow: 1;
  flex-shrink: 0;
`
