import * as c from "./constants"

export const menu = [
  // {
  //   title: "Learn",
  //   id: "topnav-learn-topic",
  //   data: [
  //     {
  //       title: "Human Capital Basics",
  //       id: "topnav-hc-basics-navigation",
  //       to: c.HUMAN_CAPITAL_BASICS,
  //     },
  //     {
  //       title: "Library",
  //       id: "topnav-library-navigation",
  //       to: c.LIBRARY,
  //       highlight: false,
  //     },
  //     {
  //       itemSeperator: true,
  //       id: "sep1",
  //     },
  //     {
  //       title: "Boost",
  //       id: "topnav-weekly-catchup-navigation",
  //       to: c.WEEKLY_CATCHUP,
  //       highlight: true,
  //     },
  //   ],
  // },
  // {
  //   title: "Who we are",
  //   id: "topnav-about-topic",
  //   data: [
  //     {
  //       title: "About us",
  //       id: "topnav-about-us-navigation",
  //       to: c.ABOUT_US,
  //     },
  //     {
  //       title: "Our commitments",
  //       id: "topnav-our-commitments-navigation",
  //       to: c.OUR_COMMITMENTS,
  //     },
  //     { title: "Blog", id: "topnav-blog-navigation", to: c.BLOG },
  //   ],
  // },
  // {
  //   title: "Who we are",
  //   id: "topnav-about-topic33",
  //   data: [
  //     {
  //       title: "About us",
  //       id: "topnav-about-us-navigation33",
  //       to: c.ABOUT_US,
  //     },
  //     {
  //       title: "Our commitments",
  //       id: "topnav-our-commitments-navigation33",
  //       to: c.OUR_COMMITMENTS,
  //     },
  //     { title: "Blog", id: "topnav-blog-navigation33", to: c.BLOG },
  //   ],
  // },
  // {
  //   title: "Who we are",
  //   id: "topnav-about-topic33fff",
  //   data: [
  //     {
  //       title: "About us",
  //       id: "topnav-about-us-navigation33ffff",
  //       to: c.ABOUT_US,
  //     },
  //     {
  //       title: "Our commitments",
  //       id: "topnav-our-commitments-navigation33fff",
  //       to: c.OUR_COMMITMENTS,
  //     },
  //     { title: "Blog", id: "topnav-blog-navigation33ffff", to: c.BLOG },
  //   ],
  // },
]
