import styled from "styled-components"
import { Link } from "gatsby"

export default styled(Link)`
  text-decoration: none;
  color: ${({ color, theme }) =>
    color ? theme.palette[color].main : theme.palette.primary.main};
  &:focus {
    outline: 0px;
  }
  @media (hover: hover) {
    & :hover {
      color: ${({ color, theme }) =>
        color ? theme.palette[color].dark : theme.palette.primary.dark};
    }
  }
`
