import React from "react"
import { connect } from "react-redux"
import styled, { css } from "styled-components"
import Link from "../Link"

import { useHasMounted } from "../../hooks/useHasMounted"

import { setPrivacyAgreeAction } from "../../containers/global/actions"

import Button from "../Button"

import { PRIVACY } from "../../navigation/constants"

function Banner(props) {
  const { setPrivacyAgree, privAgree } = props
  const hasMounted = useHasMounted()

  const handleOnAgree = React.useCallback(() => {
    setPrivacyAgree(true)
  }, [setPrivacyAgree])

  if (!hasMounted) return null

  if (privAgree) return null

  return (
    <Root show={!privAgree}>
      <Grid container>
        <Grid item mr={3}>
          <span>
            We use cookies to provide you with the best experience.{" "}
            <Link to={PRIVACY}>Learn more</Link>
          </span>
        </Grid>
        <Grid item>
          <Button onClick={handleOnAgree} title="I Accept" />
        </Grid>
      </Grid>
    </Root>
  )
}

const Root = styled.div`
  padding: 12px 12px 12px 24px;
  position: fixed;
  z-index: 1100;
  margin: 0 10px;
  max-width: unset;
  left: 0;
  right: 0;
  bottom: 24px;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 10%) 0px 12px 24px;
  background-color: rgb(255, 255, 255);
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  ${({ theme }) => theme.breakpoint.up.sm} {
    font-size: ${({ theme }) => theme.fontSize.base};
  }
  ${({ theme }) => theme.breakpoint.up.md} {
    margin: auto;
    max-width: 75%;
    right: 24px;
    left: auto;
    border-radius: 12px;
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    max-width: 50%;
  }
`

const containerCss = css`
  justify-content: space-between;
`

const itemCss = css`
  ${({ theme, mr }) => mr && `margin-right: ${theme.spacing[mr]}`};
`

const Grid = styled.div`
  display: flex;
  align-items: center;
  ${({ container }) => container && containerCss};
  ${({ item }) => item && itemCss};
`

const mapStateToProps = state => ({
  privAgree: state.global.privAgree,
})

export default connect(mapStateToProps, {
  setPrivacyAgree: setPrivacyAgreeAction,
})(Banner)
