import React from "react"
import { Provider } from "react-redux"
import { ThemeProvider } from "styled-components"
import { PrimaryLayout } from "./src/layouts"
import { GlobalStyle } from "./src/theme/globalStyle"
import theme from "./src/theme/baseTheme"
import Banner from "./src/components/Banner"

import { store } from "./src/store"

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Banner />
      <PrimaryLayout>{element}</PrimaryLayout>
    </ThemeProvider>
  </Provider>
)
