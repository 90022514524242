import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import ErrorBoundary from "../components/ErrorBoundary"
import Root from "../components/Root"
import Footer from "../components/Footer"

import { menu } from "../navigation/menu"

const Layout = props => {
  const { children } = props
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <ErrorBoundary>
      <Root inset={{ top: false }}>
        {children}
        <Footer menu={menu} />
      </Root>
    </ErrorBoundary>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
