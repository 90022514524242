import { applyMiddleware, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["global"],
}

export default rootReducer => {
  const middleware = []
  const enhancers = []

  enhancers.push(applyMiddleware(...middleware))

  // redux-persist
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(persistedReducer, composeWithDevTools(...enhancers))

  const persistor = persistStore(store, { manualPersist: false })

  return { store, persistor }
}
