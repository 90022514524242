import styled, { css } from "styled-components"

const buttonReset = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  border-radius: 0;
  background: transparent;

  appearance: none;
  box-sizing: border-box;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize 'line-height'. Cannot be changed from 'normal' in Firefox 4+. */
  line-height: normal;

  cursor: pointer;
`

export default styled.div.attrs(({ as, type }) => ({
  as: as || "button",
  type: type || "button",
}))`
  ${buttonReset}
`
