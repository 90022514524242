import * as Sentry from "@sentry/browser"

import { wrapRootElement as wrap } from "./root-wrapper"

export const onClientEntry = () => {
  Sentry.init({
    dsn: "https://d8291c7f5ae34eaca34747aff296ded7@o522212.ingest.sentry.io/5678969",
    environment: "dev",
    attachStacktrace: true,
  })

  window.Sentry = Sentry
}

export const wrapRootElement = wrap
