export const social = [
  // {
  //   id: "linkedin-official",
  //   url: "#",
  //   handle: "#",
  //   title: "LinkedIn",
  // },
  // {
  //   id: "twitter-official",
  //   url: "#",
  //   handle: "#",
  //   title: "Twitter",
  // },
  // {
  //   id: "github-official",
  //   url: "#",
  //   handle: "#",
  //   title: "GitHub",
  // },
]
