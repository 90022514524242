export const HOME = "/"

// ABOUT
export const ABOUT_US = "/about-us"
export const OUR_COMMITMENTS = "/our-commitments"
export const BLOG = "/blog"

// LEARN
export const HUMAN_CAPITAL_BASICS = "/learn"
export const LIBRARY = "/library"
export const WEEKLY_CATCHUP = "/boost"

//LEGAL
export const PRIVACY = "/legal/privacy"
export const TERMS = "/legal/terms"

//CAREER
export const CAREER = "/career"
