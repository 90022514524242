import styled, { css } from "styled-components"

const PADDING = 24
const PADDING_SM = 24
const PADDING_MD = 32
const PADDING_LG = 36
const PADDING_XL = 37

const TOPBAR = 64

const containerCss = css`
  width: 100%;
  min-width: 320px;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: ${PADDING}px;
  padding-right: ${PADDING}px;
  ${({ theme }) => theme.breakpoint.up.sm} {
    padding-left: ${PADDING_SM}px;
    padding-right: ${PADDING_SM}px;
    min-width: ${({ theme }) => theme.breakpoint.screens.sm};
    max-width: 100%;
  }
  ${({ theme }) => theme.breakpoint.up.md} {
    padding-top: ${({ inset }) => (inset ? TOPBAR : 0)}px;
    padding-bottom: ${({ inset }) => (inset ? TOPBAR : 0)}px;
    padding-left: ${PADDING_MD}px;
    padding-right: ${PADDING_MD}px;
    min-width: ${({ theme }) => theme.breakpoint.screens.md};
    max-width: 100%;
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    padding-left: ${PADDING_LG}px;
    padding-right: ${PADDING_LG}px;
    min-width: ${({ theme }) => theme.breakpoint.screens.lg};
    max-width: 100%;
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
    padding-left: ${PADDING_XL}px;
    padding-right: ${PADDING_XL}px;
    min-width: ${({ theme }) => theme.breakpoint.screens.xl};
    max-width: ${({ theme }) => theme.breakpoint.screens.xl};
  }
`

const padding = css`
  ${({ theme, pt, px }) =>
    (px || pt) && `padding-top: ${theme.spacing[px || pt]}`};
  ${({ theme, pb, px }) =>
    (px || pb) && `padding-bottom: ${theme.spacing[px || pb]}`};
  ${({ theme, pl, py }) =>
    (py || pl) && `padding-left: ${theme.spacing[py || pl]}`};
  ${({ theme, pr, py }) =>
    (py || pr) && `padding-right: ${theme.spacing[py || pr]}`};
`

const margin = css`
  ${({ theme, mt, mx }) =>
    (mx || mt) && `margin-top: ${theme.spacing[mx || mt]}`};
  ${({ theme, mb, mx }) =>
    (mx || mb) && `margin-bottom: ${theme.spacing[mx || mb]}`};
  ${({ theme, ml, my }) =>
    (my || ml) && `margin-left: ${theme.spacing[my || ml]}`};
  ${({ theme, mr, my }) =>
    (my || mr) && `margin-right: ${theme.spacing[my || mr]}`};
`

export default styled.div`
  ${containerCss};
  ${padding};
  ${margin};
`
