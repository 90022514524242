import React from "react"
import styled from "styled-components"

import ButtonBase from "../ButtonBase"

function IconButton(props) {
  const { onClick = () => {}, item, children, bgColor } = props

  const handleOnclick = React.useCallback(() => {
    onClick(item)
  }, [item, onClick])

  return (
    <StyledButtonBase onClick={handleOnclick} bgColor={bgColor}>
      {children}
    </StyledButtonBase>
  )
}

const StyledButtonBase = styled(ButtonBase)`
  width: 48px;
  height: 48px;
  transition-timing-function: cubic-bezier(0, 0, 1, 1);
  transition-duration: 200ms;
  transition-property: background;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({ bgColor, theme }) =>
    bgColor ? theme.palette.black.main : "transparent"};
  outline: none;
  margin: 0;
  padding: 0;
  @media (hover: hover) {
    & :hover {
      background-color: ${({ bgColor, theme }) =>
        bgColor ? theme.palette.black.contrastBg : "rgba(255,255,255,0.21)"};
    }
  }
`

export default IconButton
